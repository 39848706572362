import React from "react";

import NextBus from "./NextBus";
import TwitterTimeline from "./TwitterTimeline";
import { RouteComponentProps, useLocation } from "@reach/router";
import { gql, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import {
  ConstructPageTitle,
  ConstructPageTitleVariables,
} from "./__generated__/ConstructPageTitle";
import { TransitAgencyConfig } from "./config";
import { useQueryParams } from "./hooks/useQueryParams";

const TITLE_QUERY = gql`
  query ConstructPageTitle($requestedStops: [ID]!) {
    stops(filter: { idIn: $requestedStops }) {
      id
      name
    }
    stations(filter: { idIn: $requestedStops }) {
      name
    }
  }
`;

type Props = RouteComponentProps<{ stops?: string; station?: string }> & {
  config: TransitAgencyConfig;
};

export default function SchedulePage(props: Props) {
  const stops = props?.stops?.split(",") || [];
  const station = props.station;
  const requestedStops = (station ? [station] : stops) || [];

  const { data } = useQuery<ConstructPageTitle, ConstructPageTitleVariables>(
    TITLE_QUERY,
    {
      variables: { requestedStops },
      fetchPolicy: "no-cache",
    }
  );
  let maxArrivals = Math.max(
    0,
    parseInt(useQueryParams("maxArrivals"), 10) || 6
  );
  let maxPages = Math.round(
    Math.max(
      1,
      parseInt(useQueryParams("maxPages"), 10) ||
        props.config.maxNextBusPages ||
        5
    )
  );
  let pageInterval = Math.max(
    500,
    parseInt(useQueryParams("pageInterval"), 10) || 10000
  );

  const showOccupancyColumn = useQueryParams("showOccupancyColumn") !== "false";

  const stopsData = data?.stops || [];
  const stationsData = data?.stations || [];
  let pageTitle = null;
  if (stopsData.length === 0 && stationsData.length === 0) {
    pageTitle = null;
  } else if (stopsData.length > 1) {
    pageTitle = `Stops ${stopsData.map((x) => `#${x.id}`).join(", ")}`;
  } else if (stationsData.length > 0) {
    pageTitle = stationsData[0].name;
  } else {
    pageTitle = `${stopsData[0].name} (Stop #${stopsData[0].id})`;
  }

  const twitterUsername = props.config.twitterUsername;

  return (
    <section className="flex flex-grow space-x-4">
      <Helmet>{pageTitle && <title>{pageTitle}</title>}</Helmet>
      <NextBus
        className="order-1 flex-auto flex flex-col justify-between gap-4"
        stops={stops}
        station={station}
        pageTitle={pageTitle}
        showPlatformColumn={props.config.showPlatformColumn || false}
        maxArrivals={maxArrivals}
        maxPages={maxPages}
        paginationInterval={pageInterval}
        showOccupancyColumn={showOccupancyColumn}
      />
      {twitterUsername && (
        <TwitterTimeline
          className="hidden portrait:hidden lg:block order-2 flex-auto"
          username={twitterUsername}
        />
      )}
    </section>
  );
}
