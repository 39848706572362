import React from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";

import { DateTime } from "luxon";

import { time } from "./store";
import { CurrentTime } from "./__generated__/CurrentTime";
import { fullDateFormat, hugeDateFormat, timeFormat } from "./config";

const GET_TIME = gql`
  query CurrentTime {
    agencies {
      currentTime
    }
  }
`;

const pollInterval = Math.random() * 2000 + 4000;
interface Props {
  className?: string;
}

export default function Clock({ className }: Props) {
  useQuery<CurrentTime>(GET_TIME, {
    fetchPolicy: "network-only",
    pollInterval: window === undefined ? undefined : pollInterval,
    // Needed to make polling work.
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const agency = data?.agencies?.[0];
      if (agency?.currentTime) {
        time(agency.currentTime);
      }
    },
  });
  const t = useReactiveVar(time);
  const datetime = DateTime.fromISO(t, { setZone: true });

  return (
    <section className={`${className} clock`}>
      <time className="text-right leading-none align-middle">
        <div className="number-highlight font-extrabold">
          {datetime.toFormat(timeFormat)}
        </div>
        <div className="text-highlight font-medium md:hidden">
          {datetime.toFormat(fullDateFormat)}
        </div>
        <div className="text-highlight font-medium hidden md:block">
          {datetime.toFormat(hugeDateFormat)}
        </div>
      </time>
    </section>
  );
}
