import React from "react";
import logo from "../assets/york/logo.png";
import logoSm from "../assets/york/logo-sm.png";
import { TransitAgencyConfig } from "../config";

import appleTouchIcon from "../assets/york/apple-touch-icon.png";
import favicon16 from "../assets/york/favicon-16x16.png";
import safariPinnedTab from "../assets/york/safari-pinned-tab.svg";

export const headConfig = (
  <>
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
    {/* <link rel="manifest" href={siteManifest} /> */}
    <link rel="mask-icon" href={safariPinnedTab} color="#5bbad5" />
    <meta name="msapplication-TileColor" content="#ffc40d" />
    {/* <meta name="theme-color" content="#ffffff"></meta> */}
  </>
);

const config: TransitAgencyConfig = {
  headConfig,
  appName: "YRT Next Departures",
  logoUrl: logo,
  logoSmUrl: logoSm,
  // twitterUsername: "YRTViva",
  showPlatformColumn: true,
  maxNextBusPages: 3,
};
export default config;
