import React from "react";
import { Row } from "react-table";
import { GetArrivals_arrivals } from "../__generated__/GetArrivals";
import Occupancy from "./Occupancy";

// export default function Destination({row}: {row: Row<GetArrivals_arrivals>}) {
//   return (<>
//         <div className="font-semibold ">{row.original.route.longName}</div>
//         {row.original.trip.headsign && <div>to {row.original.trip.headsign}</div>}
//         {showStopInfo && (
//           <div className="lg:mt-1">
//             {showPlatformColumn && isStationPage && !row.original.stop.platformCode && (
//               <>
//                 Stop: <strong>{row.original.stop.id}</strong>
//               </>
//             )}
//             {!showPlatformColumn && row.original.stop.platformCode ? (
//               <>
//                 {" "}
//                 (Platform <strong>{row.original.stop.platformCode}</strong>)
//               </>
//             ) : null}
//           </div>
//         )}</>);
// }

export default function Destination({
  row,
  hiddenColumns,
}: {
  row: Row<GetArrivals_arrivals>;
  hiddenColumns: string[];
}) {
  return (
    <>
      <div className="font-semibold ">
        {row.original.trip.headsign}
        {!hiddenColumns.includes("occupancy") && (
          <span className="md:hidden">
            <Occupancy row={row} showEmptyPeople={false} />
          </span>
        )}
      </div>
      {!hiddenColumns.includes("stop") && (
        <div className="md:hidden">
          Stop: <strong>{row.original.stop.id}</strong>
        </div>
      )}
      {!hiddenColumns.includes("platform") && (
        <div className="md:hidden">
          (Platform <strong>{row.original.stop.platformCode}</strong>)
        </div>
      )}
    </>
  );
}
