import React, { useState, useEffect, useMemo } from "react";
import Select, {
  components,
  MultiValueProps,
  OptionProps,
  GroupTypeBase,
} from "react-select";
import { QuickScore, QuickScoreObjectResult } from "quick-score";

import { gql, useQuery } from "@apollo/client";
import {
  GetAllStopsAndStations,
  GetAllStopsAndStations_stations,
  GetAllStopsAndStations_stops,
} from "./__generated__/GetAllStopsAndStations";
import { RouteComponentProps } from "@reach/router";
import haversineDistance from "haversine-distance";
import useGeolocation from "./hooks/useGeolocation";
import { useQueryParams } from "./hooks/useQueryParams";

const GET_ALL_STOPS = gql`
  query GetAllStopsAndStations {
    stops(limit: 10000) {
      value: id
      label: name
      location {
        latitude
        longitude
      }
      parentStation
    }
    stations(limit: 10000) {
      value: id
      label: name
      location {
        latitude
        longitude
      }
      parentStation
    }
  }
`;

interface OptionType {
  item: GetAllStopsAndStations_stations | GetAllStopsAndStations_stops;
  distance?: number | null;
  score: number;
}

type FormOption =
  | {
      id: string;
      value: boolean;
      setter: (x: boolean) => void;
      name: string;
      type: "boolean";
    }
  | {
      id: string;
      value: number | null;
      setter: (x: number | null) => void;
      name: string;
      type: "number";
    };

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const SEARCH_RESULT_LIMIT = 5;
const locationBasedSortScore: (result: OptionType) => number = (result) => {
  return (result.distance || 1) * (result.score || 1);
};

function mapSearchResultsToOptions<
  T extends GetAllStopsAndStations_stations | GetAllStopsAndStations_stops
>(
  results: QuickScoreObjectResult<T>[],
  position: GeolocationPosition | null
): OptionType[] {
  // Make sure we're not processing an unbounded number of results.
  const options = results.slice(0, 5 * SEARCH_RESULT_LIMIT).map((x) => {
    return {
      ...x,
      value: x.item.value,
      label: x.item.label,
      distance: position
        ? haversineDistance(x.item.location, position.coords)
        : null,
    };
  });

  if (position) {
    options.sort((a, b) => {
      return locationBasedSortScore(a) - locationBasedSortScore(b);
    });
  }

  return options.slice(0, SEARCH_RESULT_LIMIT);
}

function humanMetricDistance(meters: number): string {
  if (meters < 5) {
    return `nearby`;
  } else if (meters < 100) {
    return `${Math.round(meters)} m`;
  } else if (meters < 1000) {
    return `${Math.round(meters / 10) * 10} m`;
  } else if (meters < 10000) {
    // Intentionally not dividing by 1000 -- we want results like "4.2 km"
    return `${Math.round(meters / 100) / 10} km`;
  } else {
    return `${Math.round(meters / 1000)} km`;
  }
}

function useEvent<K extends keyof GlobalEventHandlersEventMap>(
  event: K,
  handler: (e: GlobalEventHandlersEventMap[K]) => any,
  passive = false
) {
  useEffect(() => {
    // initiate the event handler
    window.addEventListener(event, handler, passive);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, handler);
    };
  });
}

function highlight(string: string, matches: [number, number][]) {
  const substrings = [];
  let previousEnd = 0;

  for (let [start, end] of matches) {
    const prefix = (
      <span aria-hidden>{string.substring(previousEnd, start)}</span>
    );
    const match = (
      <mark aria-hidden className="bg-transparent font-bold">
        {string.substring(start, end)}
      </mark>
    );

    substrings.push(prefix, match);
    previousEnd = end;
  }

  substrings.push(<span aria-hidden>{string.substring(previousEnd)}</span>);

  return <span>{React.Children.toArray(substrings)}</span>;
}

const MultiValue = (props: MultiValueProps<OptionType>) => (
  <components.MultiValue {...props}>
    {props.data.item.label}
    {props.data.item.__typename === "Stop" && ` (#${props.data.item.value})`}
  </components.MultiValue>
);

const Option = (props: OptionProps<OptionType, true>) => {
  const ariaLabel = `${props.data.item.label}${
    props.data.item.__typename === "Stop" && ` Stop ${props.data.item.value}`
  }`;

  return (
    <components.Option {...props} aria-label={ariaLabel}>
      {highlight(props.data.item.label, props.data.matches["label"] || [])}
      {props.data.item.__typename === "Stop" && (
        <>
          {" "}
          (Stop #
          {highlight(props.data.item.value, props.data.matches["value"] || [])})
        </>
      )}
      {props.data.distance && (
        <span className="float-right text-sm text-gray-700">
          {humanMetricDistance(props.data.distance)}
        </span>
      )}
    </components.Option>
  );
};

const positionOpts = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 60 * 1000,
};

export default function SelectorPage({ navigate }: RouteComponentProps<{}>) {
  const { data, loading } = useQuery<GetAllStopsAndStations>(GET_ALL_STOPS);

  const stopSearcher = useMemo(
    () => new QuickScore(data?.stops || [], { keys: ["value", "label"] }),
    [data?.stops]
  );

  const stationSearcher = useMemo(
    () => new QuickScore(data?.stations || [], { keys: ["label"] }),
    [data?.stations]
  );

  const geolocation = useGeolocation(positionOpts);

  const [selectedStops, setSelectedStops] = useState<string[]>([]);
  const [input, setInput] = useState<string>("");
  const [options, setOptions] = useState<GroupTypeBase<OptionType>[]>();

  const hideHelp = !!useQueryParams("hideHelp");
  useEvent("keydown", (e) => {
    if (e.key == "?") {
      if (hideHelp) {
        navigate?.("/");
      } else {
        navigate?.("/?hideHelp=true");
      }
    }
  });

  useEffect(() => {
    const strippedInput = input.trim();
    const geolocationResponse =
      geolocation && !geolocation.isError ? geolocation.response : null;
    const stationResults = mapSearchResultsToOptions(
      stationSearcher.search(strippedInput),
      geolocationResponse
    );

    const stopResults = mapSearchResultsToOptions(
      stopSearcher.search(strippedInput),
      geolocationResponse
    );

    const mappedOptions: GroupTypeBase<OptionType>[] = [];
    if (stationResults.length > 0) {
      mappedOptions.push({ label: "Stations", options: stationResults });
    }

    if (stopResults.length > 0 && strippedInput.length > 0) {
      mappedOptions.push({
        label: "Stops",
        options: stopResults,
      });
    }
    setOptions(mappedOptions);
  }, [stationSearcher, stopSearcher, input, geolocation]);

  const [maxArrivalsPerPage, setMaxArrivalsPerPage] = useState<number | null>(
    null
  );
  const [pageFlipIntervalMs, setPageFlipIntervalMs] = useState<number | null>(
    null
  );
  const [maxPagesToShow, setMaxPagesToShow] = useState<number | null>(null);

  const [showOccupancyColumn, setShowOccupancyColumn] = useState<boolean>(true);

  const opts: FormOption[] = [
    {
      id: "maxArrivals",
      value: maxArrivalsPerPage,
      setter: setMaxArrivalsPerPage,
      name: "Max arrivals per page",
      type: "number",
    },
    {
      id: "maxPages",
      value: maxPagesToShow,
      setter: setMaxPagesToShow,
      name: "Max pages",
      type: "number",
    },

    {
      id: "pageInterval",
      value: pageFlipIntervalMs,
      setter: setPageFlipIntervalMs,
      name: "Page flip interval (ms)",
      type: "number",
    },
    {
      id: "showOccupancyColumn",
      value: showOccupancyColumn,
      setter: setShowOccupancyColumn,
      name: "Show occupancy data",
      type: "boolean",
    },
  ];
  const optionsElements = (
    <div className="mb-4">
      {opts.map(({ id, value, setter, name, type }) => {
        let input = null;
        if (type === "boolean") {
          input = (
            <input
              name={id}
              id={id}
              type="checkbox"
              className="focus:ring-yrtBrandPrimary h-4 w-4 text-yrtBrandPrimary border-gray-300 rounded"
              checked={value}
              onChange={(e) => setter(e.currentTarget.checked)}
            />
          );
        } else if (type === "number") {
          input = (
            <input
              type="text"
              name={id}
              id={id}
              className="max-w-lg block w-full shadow-sm focus:ring-yrtBrandPrimary focus:border-yrtBrandPrimary sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              value={value?.toString() || ""}
              onChange={(e) => setter(Number(e.currentTarget.value) || null)}
            />
          );
        }

        return (
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pt-5">
            <label
              htmlFor={id}
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {name}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">{input}</div>
          </div>
        );
      })}
    </div>
  );

  const q = opts
    .map(({ id, value }) => {
      if (value !== null) {
        return `${id}=${value}`;
      }
      return null;
    })
    .filter((x) => x != null)
    .join("&");

  return (
    <section className="flex justify-center my-4 flex-auto">
      <h1 className="sr-only">Stop Selector</h1>
      <section className="stopselect order-1 md:min-w-1/2 md:max-w-3/4 min-w-3/4 max-w-7/8">
        {hideHelp ? null : optionsElements}
        <Select
          aria-label="Search for a stop"
          components={{ MultiValue, Option }}
          isSearchable
          isLoading={loading}
          isMulti
          // Disable react-select's built-in option filtering, since that
          // affects displaying fuzzy matching results.
          filterOption={null}
          options={options}
          noOptionsMessage={() => "No stops found"}
          onChange={(val) => {
            const stops = [val].filter(notEmpty).flat();
            const station = stops.find((x) => x.item.__typename === "Station");
            if (station) {
              navigate?.(`/station/${station.item.value}?${q}`);
            } else {
              setSelectedStops(stops.map((x) => x.item.value).filter(notEmpty));
            }
          }}
          styles={{
            placeholder: (provided, _state) => {
              const color = "#595959";

              return { ...provided, color };
            },
          }}
          onInputChange={setInput}
          placeholder="Search for a stop"
        />
        <span className="inline-flex w-full">
          <span className="inline-flex rounded-md shadow-sm justify-center mx-auto my-4">
            <button
              onClick={() => {
                const stopIds = selectedStops.join(",");
                navigate?.(`/stops/${stopIds}?${q}`);
              }}
              disabled={selectedStops.length === 0}
              type="button"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brandPrimary hover:bg-brandPrimaryLight focus:outline-none focus:border-brandPrimaryDark focus:ring-brandPrimary active:bg-brandPrimaryDark transition ease-in-out duration-150"
            >
              GO
            </button>
          </span>
        </span>
      </section>
    </section>
  );
}
