import React, { useRef } from "react";
import { Timeline } from "react-twitter-widgets";
import Autosizer from "react-virtualized-auto-sizer";

interface Props {
  username: string;
  className?: string;
}

export default function TwitterTimeline({ username, className }: Props) {
  const timelineRef = useRef<HTMLElement>(null);

  return (
    <section className={className} ref={timelineRef}>
      <Autosizer disableWidth>
        {({ height }) => {
          return (
            <Timeline
              dataSource={{
                sourceType: "profile",
                screenName: username,
              }}
              options={{
                chrome: "nofooter noborders transparent noscrollbar",
                height: height,
              }}
              onLoad={() => {
                const style = document.createElement("style");
                style.textContent =
                  '.SandboxRoot { font-family: "Open Sans",sans-serif !important; }';
                style.textContent +=
                  ".timeline-Header-title { font-weight: 600 !important; }";

                try {
                  const iframe =
                    timelineRef.current?.getElementsByTagName("iframe")?.[0];
                  iframe?.contentDocument?.head?.appendChild(style);
                } catch (e) {}
              }}
            />
          );
        }}
      </Autosizer>
    </section>
  );
}
