import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Router } from "@reach/router";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { configMap } from "./config";
import TransitHeader from "./TransitHeader";
import Clock from "./Clock";
import EmergencyAlert from "./EmergencyAlert";
import SelectorPage from "./SelectorPage";
import SchedulePage from "./SchedulePage";
import { ServerRelease } from "./__generated__/ServerRelease";
import Weather from "./Weather";

const GET_SERVER_RELEASE = gql`
  query ServerRelease {
    release
    agencies {
      name
    }
  }
`;

export default function App() {
  const { data, error } = useQuery<ServerRelease>(GET_SERVER_RELEASE, {
    fetchPolicy: "cache-and-network",
    pollInterval: 60 * 1000,
    notifyOnNetworkStatusChange: true,
  });
  const [expectedRelease, setExpectedRelease] = useState<string | null>(null);

  if (error) {
    console.error(error);
  }

  if (!expectedRelease && data) {
    console.log(`Setting server release version to ${data.release}`);
    setExpectedRelease(data.release);
  } else if (data?.release && expectedRelease !== data.release) {
    console.error(
      `Currently on release ${expectedRelease}, but found release ${data.release} on the server`
    );
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  }

  const configKey = expectedRelease?.split("-")[0] || "unknown";
  const config = configMap[configKey] || {};
  const agencyName = data?.agencies?.[0]?.name;

  const app = (
    <div className="flex flex-col h-screen">
      {/* Transform needed for the shadow effect */}
      <header className="px-4 h-20 sm:h-20 md:h-24 lg:h-32 xl:h-32 portrait-md:h-32 portrait-lg:h-64 flex flex-none order-1 bg-white justify-between items-center shadow">
        <TransitHeader
          logoUrl={config.logoUrl}
          logoSmUrl={config?.logoSmUrl || null}
          className="order-1"
        />
        <div className="order-2 flex divide-x gap-4">
          <Clock className="order-1" />
          <Weather className="order-3" />
        </div>
      </header>
      <main className="flex flex-auto flex-col order-2 overflow-hidden items-stretch">
        {/* Primary=false should fix mobile scroll focus issues for now. A better fix is proposed in - https://stackoverflow.com/a/56996986. */}
        <Router
          className="bg-brandPrimaryDesat text-base text-black flex-auto flex"
          primary={false}
        >
          <SchedulePage config={config} path="/station/:station" />
          <SchedulePage config={config} path="/stops/:stops" />
          <SelectorPage default />
        </Router>
        {/* <EmergencyAlert /> */}
      </main>
      {!process.env.REACT_APP_REMOVE_DEBUG_INFO && (
        <footer
          className=" px-4 bottom-0 text-right w-full sr-none text-base flex-none font-mono text-gray-700 fixed"
          aria-hidden
        >
          {process.env.REACT_APP_SENTRY_RELEASE?.substr(0, 6) || "HEAD"}
        </footer>
      )}
    </div>
  );

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`${config.appName} - %s`}
        defaultTitle={config.appName}
      >
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`View the next departures for your ${agencyName} bus or train.`}
        />
        {config.headConfig}
      </Helmet>
      {app}
    </HelmetProvider>
  );
}
