/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum OccupancyStatus {
  CRUSHED_STANDING_ROOM_ONLY = "CRUSHED_STANDING_ROOM_ONLY",
  EMPTY = "EMPTY",
  FEW_SEATS_AVAILABLE = "FEW_SEATS_AVAILABLE",
  FULL = "FULL",
  MANY_SEATS_AVAILABLE = "MANY_SEATS_AVAILABLE",
  NOT_ACCEPTING_PASSENGERS = "NOT_ACCEPTING_PASSENGERS",
  STANDING_ROOM_ONLY = "STANDING_ROOM_ONLY",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
