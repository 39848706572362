import React from "react";
import { Row } from "react-table";
import { GetArrivals_arrivals } from "../__generated__/GetArrivals";
import { OccupancyStatus } from "../__generated__/globalTypes";

class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}

function iterator(x: number) {
  return [...Array(x)];
}

type OccupancyHighlight = [number, string];

export function legendPeople(n: number, className: string) {
  return people([[n, "occupancy-occupied"]], className);
}

function people(
  x: OccupancyHighlight[],
  className = "h-4 md:h-8 lg:h-12 inline-block"
) {
  const one = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      />
    </svg>
  );
  return (
    <>
      {x.map(([num, className]) => {
        return (
          <span className={className}>{iterator(num).map(() => one)}</span>
        );
      })}
    </>
  );
}

function makeOccupancyHighlight(
  highlight: number,
  total: number
): OccupancyHighlight[] {
  return total < highlight
    ? [[highlight, "occupancy-occupied"]]
    : [
        [highlight, "occupancy-occupied"],
        [total - highlight, "occupancy-unoccupied"],
      ];
}

function mapOccupancyToLoadIndicator(
  occ: OccupancyStatus | null,
  showEmptyPeople: boolean
): [string, JSX.Element | null] {
  if (!occ) {
    return ["", null];
  }

  const total = showEmptyPeople ? 3 : -1;

  switch (occ) {
    case OccupancyStatus.EMPTY:
      return ["Empty", people(makeOccupancyHighlight(0, total))];
    case OccupancyStatus.MANY_SEATS_AVAILABLE:
      return ["Many seats available", people(makeOccupancyHighlight(1, total))];
    case OccupancyStatus.FEW_SEATS_AVAILABLE:
      return ["Few seats available", people(makeOccupancyHighlight(2, total))];
    case OccupancyStatus.STANDING_ROOM_ONLY:
      return ["Standing room only", people(makeOccupancyHighlight(2, total))];
    case OccupancyStatus.CRUSHED_STANDING_ROOM_ONLY:
      return [
        "Crushed standing room only",
        people(makeOccupancyHighlight(3, total)),
      ];
    case OccupancyStatus.FULL:
      return ["Full", people(makeOccupancyHighlight(3, total))];
    case OccupancyStatus.NOT_ACCEPTING_PASSENGERS:
      return ["Not accepting passengers", null];
    default:
      throw new UnreachableCaseError(occ);
  }
}

export default function Occupancy({
  row,
  showEmptyPeople,
}: {
  row: Row<GetArrivals_arrivals>;
  showEmptyPeople: boolean;
}) {
  const [occupancyLabel, occupancyStatus] = mapOccupancyToLoadIndicator(
    row.original.vehicle?.occupancyStatus || null,
    showEmptyPeople
  );
  return (
    <div className="right h-full" role="img" aria-label={occupancyLabel}>
      {occupancyStatus}
    </div>
  );
}
