import { DateTime } from "luxon";
import React from "react";
import { Row } from "react-table";
import { GetArrivals_arrivals } from "../__generated__/GetArrivals";
import { timeFormat } from "../config";
import { time } from "../store";
import { useReactiveVar } from "@apollo/client";

function mins(currentTime: DateTime, dt: DateTime) {
  const value = Math.round(currentTime.until(dt).toDuration().as("minutes"));

  if (Number.isNaN(value)) {
    console.error("Found NaN edge case", currentTime, dt);
    return 0;
  }

  return value;
}

function screenreader(currentTime: DateTime, arrival: GetArrivals_arrivals) {
  const dt = DateTime.fromISO(arrival.sortKey, { setZone: true });
  const m = mins(currentTime, dt);
  let strr = `${m} minutes`;
  if (m > 60) {
    const hrs = Math.floor(m / 60);
    const residual = m - 60 * hrs;
    if (residual === 0) {
      strr = `${hrs} hours`;
    } else {
      strr = `${hrs} hours and ${residual} minutes`;
    }
  }

  return `${arrival.type} ${strr}`;
}

function humanize(currentTime: DateTime, arrival: GetArrivals_arrivals) {
  const dt = DateTime.fromISO(arrival.sortKey, { setZone: true });
  const m = mins(currentTime, dt);
  if (m >= 60) {
    return dt.setZone(currentTime.zoneName).toFormat(timeFormat);
  }

  if (m <= 0) {
    return "Due";
  }

  return `${m} min${m !== 1 ? "s" : ""}`;
}

export default function Departing({ row }: { row: Row<GetArrivals_arrivals> }) {
  const currentTime = DateTime.fromISO(useReactiveVar(time), { setZone: true });
  return humanize(currentTime, row.original);
}

export function shouldHighlightRow({
  currentTime,
  row,
}: {
  currentTime: DateTime;
  row: Row<GetArrivals_arrivals>;
}) {
  return (
    mins(
      currentTime,
      DateTime.fromISO(row.original.sortKey, { setZone: true })
    ) <= 2
  );
}
