import React, { useEffect, useMemo } from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Alert, alerts } from "./store";

import { EmergencyAlert as EmergencyAlertData } from "./__generated__/EmergencyAlert";
import { Column, usePagination, useTable } from "react-table";

const GET_EMERGENCY_ALERT = gql`
  query EmergencyAlert {
    emergencyAlert {
      title
      level
      message
    }
  }
`;
const updateTimeFuzz = Math.floor(Math.random() * (5000 + 1)) + 2500;
interface Props {
  className?: string;
  paginationInterval?: number;
}

const classNamesForLevel: Record<string, string> = {
  warn: "text-yrtBrandYellow bg-yrtBrandGrey",
  default: "text-yrtBrandYellow bg-yrtBrandGrey",
};

export default function EmergencyAlert({
  className,
  paginationInterval = 10000,
}: Props) {
  const { data } = useQuery<EmergencyAlertData>(GET_EMERGENCY_ALERT, {
    fetchPolicy: "cache-and-network",
    // Needed to make polling work.
    notifyOnNetworkStatusChange: true,
    pollInterval: window === undefined ? undefined : updateTimeFuzz,
  });
  const alertMap = useReactiveVar(alerts);

  const columns: Column<Alert>[] = useMemo(() => {
    return [
      {
        id: "header",
      },
      {
        id: "body",
      },
    ];
  }, []);

  const emergencyAlert = data?.emergencyAlert;

  if (emergencyAlert) {
    alertMap["emergencyAlert"] = {
      id: "emergencyAlert",
      header: emergencyAlert.title,
      body: emergencyAlert.message,
    };
  }

  const {
    page,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: Object.values(alertMap),
      autoResetPage: false,
      initialState: {
        pageSize: 1,
      },
    },
    usePagination
  );

  useEffect(() => {
    const interval = setInterval(() => {
      canNextPage && pageIndex + 1 < pageCount ? nextPage() : gotoPage(0);
    }, paginationInterval);
    return () => clearInterval(interval);
  }, [paginationInterval, canNextPage, pageIndex, nextPage, gotoPage]);

  return (
    <>
      {page.map((row, i) => (
        <section
          className={`${className || ""} emergency-alert ${
            // classNamesForLevel[alert?.level] || "default"
            classNamesForLevel["default"]
          }`}
        >
          {row.original.header.trim() && (
            <h2 className="font-semibold text-highlight">
              {row.original.header}
            </h2>
          )}
          {row.original.body?.trim() && (
            <div
              className={
                row.original.header.trim() ? "text-2xl pt-2" : "text-highlight"
              }
            >
              {row.original.body}
            </div>
          )}
        </section>
      ))}
    </>
  );
}
