import { makeVar, ReactiveVar } from "@apollo/client";

export type Alert = {
  id: string | null;
  header: string;
  body: string | null;
};

export const time: ReactiveVar<string> = makeVar(new Date().toISOString());
export const alerts: ReactiveVar<Record<string, Alert>> = makeVar({});
