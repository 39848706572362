import React, { useMemo, useRef, useEffect } from "react";
import { useQuery, gql, useReactiveVar } from "@apollo/client";
import { useTable, Column, useSortBy, usePagination, Row } from "react-table";

import {
  GetArrivals,
  GetArrivalsVariables,
  GetArrivals_arrivals,
} from "./__generated__/GetArrivals";
import ErrorState from "./ErrorState";
import Occupancy, { legendPeople } from "./cells/Occupancy";
import Departing, { shouldHighlightRow } from "./cells/Departing";
import { DateTime } from "luxon";
import Destination from "./cells/Destination";
import { time, alerts, type Alert } from "./store";
import EmergencyAlert from "./EmergencyAlert";

const GET_ARRIVALS = gql`
  query GetArrivals($station: ID, $stops: [ID]!, $limit: Int) {
    arrivals(filter: { stops: $stops, station: $station }, limit: $limit) {
      stop {
        id
        platformCode
        alerts {
          id
          header
          body
        }
      }
      route {
        id
        shortName
        longName
        alerts {
          id
          header
          body
        }
      }
      trip {
        headsign
        alerts {
          id
          header
          body
        }
      }
      type: typeAsString
      sortKey: departure
      vehicle {
        occupancyStatus
      }
    }
  }
`;

const headerCommonClasses =
  "table-cell text-lg md:text-2xl align-middle font-bold px-2 md:px-4";
const cellCommonClasses = "table-cell py-1 px-2 md:py-2 md:px-4";

// Value between -2500 and 2500 inclusive
const updateTimeFuzz = Math.floor(Math.random() * (5000 + 1)) - 2500;
const updateTime = 17500 + updateTimeFuzz;
interface Props {
  stops?: string[];
  station?: string | null;
  limit?: number;
  pageTitle?: string | null;
  className?: string;
  maxArrivals: number;
  maxPages: number;
  paginationInterval?: number;
  showPlatformColumn: boolean;
  showOccupancyColumn: boolean;
}

const sorting = [{ id: "departing", desc: false }];
type ExtendedColumn<D extends object = {}> = {
  headerClassName: () => string;
  cellClassName: () => string;
} & Column<D>;

export default function NextBus({
  stops,
  station,
  limit,
  className,
  pageTitle,
  maxPages,
  maxArrivals,
  paginationInterval = 10000,
  showPlatformColumn,
  showOccupancyColumn,
}: Props) {
  limit = Math.min(30, Math.max(1, limit || 30));
  const hasMultipleStops = !!(station || (stops || []).length > 1);
  const isStationPage = !!station;

  const hiddenColumns = useMemo(() => {
    const defaultHidden: string[] = showOccupancyColumn ? [] : ["occupancy"];
    if (isStationPage) {
      // Don't display stops for a station
      return defaultHidden.concat("stop");
    }

    if (hasMultipleStops) {
      return defaultHidden.concat("platform");
    }
    return defaultHidden.concat("platform", "stop");
  }, [hasMultipleStops, isStationPage, showOccupancyColumn]);

  const table = useRef<HTMLTableElement>(null);
  const columns: ExtendedColumn<GetArrivals_arrivals>[] = useMemo(() => {
    return [
      {
        id: "route",
        accessor: (row, _idx) => row.route.shortName,
        Header: "Route",
        headerClassName: () => `${headerCommonClasses} text-center min-w-1/8`,
        cellClassName: () =>
          `${cellCommonClasses} align-middle text-center number-highlight`,
      },
      {
        id: "destination",
        accessor: (row, _idx) => row.trip.headsign,
        Cell: ({ row }: { row: Row<GetArrivals_arrivals> }) => {
          return <Destination row={row} hiddenColumns={hiddenColumns} />;
        },
        Header: "Destination",
        headerClassName: () => `${headerCommonClasses}`,
        cellClassName: () =>
          `${cellCommonClasses} align-middle mx-4 py-2 portrait:py-2 lg:py-0 text-highlight tracking-tighter leading-tight`,
      },
      {
        id: "occupancy",
        accessor: (row, _idx) => row.vehicle?.occupancyStatus,
        Header: "Capacity",
        headerClassName: () => `${headerCommonClasses} hidden md:table-cell `,
        cellClassName: () =>
          `${cellCommonClasses} hidden md:table-cell align-middle `,
        Cell: ({ row }: { row: Row<GetArrivals_arrivals> }) => (
          <Occupancy row={row} showEmptyPeople={false} />
        ),
      },
      {
        id: "departing",
        accessor: (row, _idx) =>
          DateTime.fromISO(row.sortKey, { setZone: true }),
        Header: "Departing",
        Cell: Departing,
        headerClassName: () => `${headerCommonClasses} w-1/6 text-center`,
        cellClassName: () =>
          // `minutes` class here pads out the title to make room for the realtime icon
          `${cellCommonClasses} align-middle text-right number-highlight minutes`,
      },
      {
        id: "platform",
        accessor: (row, _idx) => row.stop.platformCode,
        Header: "Platform",
        headerClassName: () =>
          `${headerCommonClasses} hidden md:table-cell text-center`,
        cellClassName: () =>
          `${cellCommonClasses} hidden md:table-cell align-middle text-center number-highlight`,
      },
      {
        id: "stop",
        accessor: (row, _idx) => row.stop.id,
        Header: "Stop",
        headerClassName: () =>
          `${headerCommonClasses} hidden md:table-cell  text-center`,
        cellClassName: () =>
          `${cellCommonClasses} hidden md:table-cell align-middle text-center number-highlight`,
      },
    ];
  }, []);

  const { loading, data, error } = useQuery<GetArrivals, GetArrivalsVariables>(
    GET_ARRIVALS,
    {
      variables: { limit, stops: stops || [], station },
      pollInterval: updateTime,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );
  const currentTime = DateTime.fromISO(useReactiveVar(time), { setZone: true });

  const tableData = useMemo(() => {
    return data?.arrivals ? data.arrivals : [];
  }, [data?.arrivals]);

  const pluckedAlerts: Record<string, Alert> = {};
  const addAlert = (alert: Alert) => {
    pluckedAlerts[alert.id || "unknown"] = alert;
  };
  tableData.forEach((arrival) => {
    arrival.route.alerts?.forEach(addAlert);
    arrival.stop.alerts?.forEach(addAlert);
    arrival.trip.alerts?.forEach(addAlert);
  });
  alerts(pluckedAlerts);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      autoResetPage: false,
      initialState: {
        pageSize: maxArrivals,
        sortBy: sorting,
        hiddenColumns,
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const interval = setInterval(() => {
      canNextPage && pageIndex + 1 < maxPages ? nextPage() : gotoPage(0);
    }, paginationInterval);
    return () => clearInterval(interval);
  }, [
    paginationInterval,
    canNextPage,
    pageIndex,
    nextPage,
    gotoPage,
    maxPages,
  ]);

  if (error) {
    console.error(error);
    return <ErrorState error={error} />;
  }

  const occupancyLegend = (
    <div className="md:flex items-center hidden md:gap-x-8">
      <div>
        Not Crowded
        {legendPeople(1, "h-4 xl:h-8 inline-block")}
      </div>
      <div>
        Some Crowding
        {legendPeople(2, "h-4 xl:h-8 inline-block")}
      </div>
      <div>
        Crowded
        {legendPeople(3, "h-4 xl:h-8 inline-block")}
      </div>
    </div>
  );

  return (
    <section className={className}>
      <h1 className="sr-only">
        Upcoming departures {pageTitle ? `for ${pageTitle}` : null}
      </h1>
      <div
        ref={table}
        {...getTableProps({
          className: `table table-auto w-full border-collapse`,
        })}
      >
        {headerGroups.map((headerGroup) => (
          <div
            {...headerGroup.getHeaderGroupProps({
              className:
                "table-header-group font-bold border-b border-gray-400",
            })}
          >
            <div className="text-highlight table-row py-4">
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps({
                    className: (column as any).headerClassName(),
                  })}
                >
                  {column.render("Header")}
                </div>
              ))}
            </div>
          </div>
        ))}

        <div {...getTableBodyProps({ className: "table-row-group" })}>
          {page.map((row, _i) => {
            prepareRow(row);

            return (
              <div
                {...row.getRowProps({
                  className: `table-row trip border-b border-gray-400 ${
                    row.original.type
                  } ${
                    shouldHighlightRow({ currentTime, row }) ? "inverted" : ""
                  }`,
                })}
              >
                {row.cells.map((cell) => {
                  return (
                    <div
                      {...cell.getCellProps({
                        className: (cell.column as any).cellClassName(),
                      })}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {!loading && rows.length === 0 ? (
        <>
          <div className="h-full w-full text-center pt-32">
            No arrivals found.
          </div>
        </>
      ) : (
        <div>
          <div className="border-t-2 border-yrtBrandPrimary flex flex-col md:flex-row w-full gap-2 md:gap-4 p-2 md:p-4 text-base lg:text-2xl text-yrtBrandPrimary justify-between ">
            <div className="font-bold whitespace-nowrap">
              {pageSize !== 0 && maxPages > 1 && (
                <>
                  Page {pageIndex + 1} of {Math.min(pageCount, maxPages)}
                </>
              )}
            </div>
            {showOccupancyColumn && occupancyLegend}
            <div className="estimated legend">Real-time Departure</div>
          </div>
          <EmergencyAlert className="grow-0 flex-shrink p-2 md:p-4 tracking-normal" />
        </div>
      )}
    </section>
  );
}
