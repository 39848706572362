import React from "react";

import { gql, useQuery } from "@apollo/client";
import { GetWeather } from "./__generated__/GetWeather";

const WEATHER_QUERY = gql`
  query GetWeather {
    weather {
      temp
      description
    }
  }
`;

interface Props {
  className?: string;
}

const updateTimeFuzz = Math.floor(Math.random() * 5000) + 30000;

export default function Weather({ className }: Props) {
  const { data } = useQuery<GetWeather>(WEATHER_QUERY, {
    fetchPolicy: "no-cache",
    // Needed to make polling work.
    notifyOnNetworkStatusChange: true,
    pollInterval: window === undefined ? undefined : updateTimeFuzz,
  });

  if (!data?.weather) {
    return null;
  }

  return (
    <div className={`${className} pl-4`}>
      <div className="inline flex-1 text-center leading-none align-middle ">
        {/* <img src={`https://openweathermap.org/img/wn/${icon}@2x.png`} alt={description} loading="lazy" className="mx-auto w-8" /> */}
        <div className="number-highlight font-extrabold dark:text-gray-300">
          {Math.round(data.weather.temp)} &deg;C
        </div>
        <div className="text-highlight font-medium dark:text-gray-400">
          {data.weather.description}
        </div>
      </div>
    </div>
  );
}
